<template>
  <div>请登录</div>
</template>
<script setup>
import { defineComponent, reactive, ref, toRaw, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";

import { getUrlParams } from "@/utils/common";
import { getToken, setToken } from "@/utils/authCookie";

var url = window.location.href;
var param = getUrlParams(url);

let router = useRouter();
if (param.nickname) {
  localStorage.setItem('sc_nickename', param.nickname);
}
if (param.token) {
  setToken(param.token);
  router.push({ name: "home" });
}

</script>
